:root {
  --background-color: #fff;
  --accent-color: #ff4081;
  --light-gray: #F0F0F0;

  --red-100: #ffc8ba;
  --red-400: #ff6040;
  --green-100: #daffc5;
  --green-900: #009b00;
  --orange: #ff9500;

  --theme-blue: rgb(63, 81, 181);
  --theme-pink: #ff4081;
}

/* You can add global styles to this file, and also import other style files */
@import "material-overrides.css";
@import "../node_modules/quill/dist/quill.snow.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: var(--background-color);
  overflow-x: hidden;
}

.authenticating-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 10vw;
  background: #fff;
  opacity: 0.8;
  animation: pulsate 0.6s infinite alternate linear;
}

@keyframes pulsate {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}

.mat-mdc-card {
  margin-bottom: 1em;
}

textarea {
  min-height: 100px;
}

.floating-fab {
  position: fixed !important;
  bottom: 2em;
  right: 2em;
}

.floating-fab-1 {
  bottom: calc(2em + 60px);
}

.floating-fab-2 {
  bottom: calc(2em + 120px);
}

.page {
  padding: 1em;
}

.spacer {
  flex: 1 1 auto;
}

.no-padding {
  padding: 0 !important;
}

.full-page {
  background-color: #eee;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100vh - 54px);
}

.full-page>mat-toolbar {
  flex: 0 0 auto;
}

.full-page-content {
  flex: 1;
  overflow-y: auto;
  padding: 8px 0;
  width: calc(100% - 16px);
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

div[mat-subheader],
mat-toolbar>span {
  text-transform: uppercase;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
}

.mat-mdc-progress-bar {
  position: absolute !important;
  left: 0;
  bottom: 0 !important;
  width: 100vw !important;
}

.small-page {
  max-width: 720px;
  margin: 0 auto;
}

.horizontal-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.horizontal-form .mat-mdc-form-field {
  flex: 1 1 auto;
  max-width: 50%;
}

.mb-100 {
  margin-bottom: 100px;
}

.no-items-to-display {
  font: 500 20px/32px Roboto,
    "Helvetica Neue",
    sans-serif;
  text-align: center;
  position: relative;
  left: 0;
  top: 35vh;
  width: calc(100% - 2em);
  color: #999;
  padding: 1em;
}

.max-width-1400 {
  max-width: 1400px;
  margin: 0 auto;
}

.plr-16 {
  padding: 0 16px;
}

@page {
  margin: 0;
}

.toolbar-title {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.page-with-toolbar {
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100svh - 50px);
  overflow: hidden;

  background-color: #eee;
}

.page-with-toolbar-content {
  overflow-y: auto;
  padding: 0.5rem;
}

/* Quill Editor overrides */
.ql-container {
  font-size: 1rem;
  font-family: inherit;
  letter-spacing: 0.5px;

  &:has([contenteditable="true"]) {
    background-color: var(--mdc-filled-text-field-container-color);
  }
}

.txt-small .ql-container {
  font-size: 1rem;
}

.txt-large .ql-container {
  font-size: 1.5rem;
}

quill-view .ql-editor {
  padding-left: 0;
  padding-right: 0;
}

.ql-editor .ql-size-large {
  font-size: 20px;
  font-weight: 500;
}

.ql-toolbar.ql-snow {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
}